import React from 'react';
const Header = () => {
  return (
    <div className='container-bg'>
      <iframe
        title='header'
        className='w-full hidden md:block bg-[#001A31] h-12 pointer-events-none'
        src='https://s.tradingview.com/embed-widget/ticker-tape/?locale=uk#%7B%22symbols%22%3A%5B%7B%22proName%22%3A%22FOREXCOM%3ASPXUSD%22%2C%22title%22%3A%22S%26P%20500%22%7D%2C%7B%22proName%22%3A%22FOREXCOM%3ANSXUSD%22%2C%22title%22%3A%22COINHAKO%20100%22%7D%2C%7B%22description%22%3A%22BIT%2FJPY%22%2C%22proName%22%3A%22BITFINEX%3ABTCJPY%22%7D%2C%7B%22description%22%3A%22ASXGOLD%22%2C%22proName%22%3A%22ASX%3AGOLD%22%7D%2C%7B%22description%22%3A%22AUDSILVER%22%2C%22proName%22%3A%22FX_IDC%3AXAGAUD%22%7D%5D%2C%22showSymbolLogo%22%3Atrue%2C%22colorTheme%22%3A%22dark%22%2C%22isTransparent%22%3Atrue%2C%22displayMode%22%3A%22adaptive%22%2C%22width%22%3A%22100%25%22%2C%22height%22%3A44%2C%22utm_source%22%3A%22xgame.gd11.sunbur77.club%22%2C%22utm_medium%22%3A%22widget%22%2C%22utm_campaign%22%3A%22ticker-tape%22%7D'
      />
    </div>
  );
};

export default Header;
